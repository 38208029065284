import { computePosition, flip, offset, arrow, autoUpdate, Placement } from "@floating-ui/dom";
import classNames from "classnames";
import {
  Children,
  cloneElement,
  ElementRef,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";

interface Position {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

const positionReset: Position = {
  top: "",
  bottom: "",
  left: "",
  right: "",
};

function updateTooltipPosition({
  childRef,
  tooltipRef,
  placement,
}: {
  childRef: RefObject<HTMLElement>;
  tooltipRef: RefObject<ElementRef<"div">>;
  placement: Placement;
}) {
  const childEl = childRef.current;
  const tooltipEl = tooltipRef.current;

  if (!childEl || !tooltipEl) return;

  void computePosition(childEl, tooltipEl, {
    placement,
    middleware: [
      offset(6), // offsets tooltip from a reference element
      flip(), // finds a placement that will fit (e.g., flip "top" to "bottom")
    ],
  }).then(({ x, y, placement, middlewareData }) => {
    // position the tooltip DOM element
    Object.assign(tooltipEl.style, {
      left: `${x}px`,
      top: `${y}px`,
    });
  });
}

const Tooltip = ({
  children,
  content,
  disableOnHover,
  isTooltipOpen = false,
  className,
  enterDelay,
  exitDelay,
  animationTimeout = 100,
  placement = "top",
}: {
  content: ReactNode;
  children: ReactElement;
  disableOnHover?: boolean;
  isTooltipOpen?: boolean;
  className?: string;
  enterDelay?: number;
  exitDelay?: number;
  placement?: Placement;
  animationTimeout?: number;
}) => {
  const [isTooltipShowing, setIsTooltipShowing] = useState(isTooltipOpen ?? false);

  const childRef = useRef<HTMLElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const enterTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const exitTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    updateTooltipPosition({ childRef, tooltipRef, placement });
  }, [isTooltipShowing, placement]);

  useEffect(() => {
    setIsTooltipShowing(isTooltipOpen);
  }, [isTooltipOpen]);

  const showTooltip = useCallback(() => {
    clearTimeout(exitTimeoutRef.current);
    if (enterDelay) {
      enterTimeoutRef.current = setTimeout(() => {
        setIsTooltipShowing(true);
      }, enterDelay);
    } else {
      setIsTooltipShowing(true);
    }
  }, [enterDelay]);

  const hideTooltip = useCallback(() => {
    clearTimeout(enterTimeoutRef.current);
    if (exitDelay) {
      exitTimeoutRef.current = setTimeout(() => {
        setIsTooltipShowing(false);
      }, exitDelay);
    } else {
      setIsTooltipShowing(false);
    }
  }, [exitDelay]);

  // updates position when scrolling or resizing
  useEffect(() => {
    if (!childRef.current || !tooltipRef.current) return;
    autoUpdate(childRef.current, tooltipRef.current, () => {
      updateTooltipPosition({ childRef, tooltipRef, placement });
    });

    if (isTooltipShowing) {
      showTooltip();
    } else {
      hideTooltip();
    }
  }, [hideTooltip, isTooltipShowing, placement, showTooltip]);

  const onHoverEnter = () => {
    if (disableOnHover) return;
    showTooltip();
  };

  const onHoverLeave = () => {
    if (disableOnHover) return;
    hideTooltip();
  };

  return (
    <>
      {cloneElement(Children.only(children), {
        ref: childRef,
        onMouseEnter: onHoverEnter,
        onMouseLeave: onHoverLeave,
        onFocus: onHoverEnter,
        onBlur: onHoverLeave,
        ...(children.props as Record<string, unknown>),
      })}
      <CSSTransition
        in={isTooltipShowing}
        nodeRef={tooltipRef}
        timeout={animationTimeout}
        classNames="fade"
        mountOnEnter
        unmountOnExit
      >
        <div
          ref={tooltipRef}
          role="tooltip"
          className={classNames(
            "z-tooltip rounded-small bg-background-90 font-style-body-b2 pointer-events-none absolute p-3 !leading-4 text-white",
            "[&_.fade-enter-active]:translate-y-0 [&_.fade-enter-active]:opacity-100 [&_.fade-enter-active]:transition-opacity [&_.fade-enter]:translate-y-2 [&_.fade-enter]:opacity-0",
            "[&_.fade-exit-active]:translate-y-2 [&_.fade-exit-active]:opacity-0 [&_.fade-exit-active]:transition-opacity [&_.fade-exit]:translate-y-0 [&_.fade-exit]:opacity-100",
            className,
          )}
          style={{ transitionDuration: `${animationTimeout}ms` }}
        >
          {content}
        </div>
      </CSSTransition>
    </>
  );
};

export { Tooltip };
